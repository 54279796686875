import 'components/Controller/index.scss';
import { Unity, useUnityContext } from "react-unity-webgl";

export default function Controller({ disabledAdd, disabledRemove, onClickAdd, onClickRemove }) {

    return (
        <div className="controller-container">
            <button disabled={disabledAdd} onClick={onClickAdd}>추가</button>
            <button disabled={disabledRemove} onClick={onClickRemove}>삭제</button>
        </div>
    );

};
