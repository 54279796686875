import 'components/Viewer/index.scss';
import { Unity, useUnityContext } from "react-unity-webgl";
import Utility from "modules/Utility";

let beforeLoadingProgression = -1;
let isEmitLoadEvent = false;
export default function Viewer( { onProgress, onLoad } ) {

    const instance = useUnityContext({
        loaderUrl: `${process.env.REACT_APP_UNITY_LOADER_END_POINT}/build.loader.js`,
        dataUrl: `${process.env.REACT_APP_UNITY_LOADER_END_POINT}/build.data`,
        frameworkUrl: `${process.env.REACT_APP_UNITY_LOADER_END_POINT}/build.framework.js`,
        codeUrl: `${process.env.REACT_APP_UNITY_LOADER_END_POINT}/build.wasm`,
        showBanner: false
    });

    if( beforeLoadingProgression !== instance.loadingProgression ){
        beforeLoadingProgression = instance.loadingProgression;
        onProgress( instance.loadingProgression );
    }

    if( instance.isLoaded && !isEmitLoadEvent ){
        isEmitLoadEvent = true;

        const unity = {

            request: (method, ...parameters) => {
                return new Promise((resolve) => {

                    const callId = Utility.randomString();
                    const listener = (event) => {
                        if( callId !== event.detail.id ){
                            return;
                        }

                        window.removeEventListener( `command.${method}`, listener );
                        resolve( event.detail );
                    };
                    window.addEventListener( `command.${method}`, listener );
                    parameters.unshift(callId);
                    instance.sendMessage( "SmartFactory", method, parameters.join("::") );
                });
            }

        };
        onLoad( unity );

    }

    return <Unity className="unity" unityProvider={instance.unityProvider}/>;

};
