import 'components/ObjectDetail/index.scss';

export default function ObjectDetail({ objectId, x, y, z }) {

    return (
        <div className="object-detail-container">
            <div>{objectId}</div>
            <div>
                <input value={x}/>
            </div>
            <div>
                <input value={y}/>
            </div>
            <div>
                <input value={z}/>
            </div>
        </div>
    );

};
