import {useState} from "react";
import Viewer from "components/Viewer";
import Controller from "./components/Controller";
import ObjectDetail from "./components/ObjectDetail";
import Jwt from 'modules/Jwt';
import Utility from "./modules/Utility";
import 'App.css';

Jwt.get();
let unity = null;
function App() {

    const [isLoadedUnity, setLoadedUnity] = useState(false);
    const [selectedObject, setSelectedObject] = useState(null);

    const methods = {

        selectObject: async (id) => {

            const response = await unity.request( "selectObject", id );
            const selectedObjectId = response.data;
            setSelectedObject( selectedObjectId );
            return response;

        },

        deselectObject: async () => {
            const response = await unity.request("deselectObject");
            if( response.result !== "SUCCESS" ){
                return response;
            }

            setSelectedObject( null );
            return response;
        },

        removeObject: async (id) => {

            if( id === selectedObject ){
                await methods.deselectObject();
            }

            return await unity.request( "removeObject", id );

        },

        onViewerProgress: ( value ) => {
            console.log(value * 100);
        },

        onViewerLoad: ( instance ) => {

            unity = instance;

            window.addEventListener("event.clickObject", ( event ) => {

                const objectId = event.detail.data;
                methods.selectObject( objectId );

            });

            window.addEventListener("event.updateCamera", ( event ) => {

                console.log( event.detail.data );

            });

            setLoadedUnity(true);
        },

        onClickControllerAdd: async () => {

            const objectId = Utility.randomString();
            const addResponse = await unity.request("addObject", objectId);
            if( addResponse.result !== "SUCCESS" ){
                return addResponse;
            }

            return methods.selectObject( objectId );
        },

        onClickControllerRemove: async () => {

            return methods.removeObject( selectedObject );

        }

    };

    return (
        <div className="App">
            <Viewer onProgress={methods.onViewerProgress} onLoad={methods.onViewerLoad}></Viewer>
            <div className="controller">
                <Controller
                    disabledAdd={!isLoadedUnity}
                    disabledRemove={!isLoadedUnity || selectedObject === null}
                    onClickAdd={methods.onClickControllerAdd}
                    onClickRemove={methods.onClickControllerRemove}>
                </Controller>
            </div>
            <div className={`object-detail${selectedObject ? " show" : ""}`}>
                <ObjectDetail objectId={selectedObject} x={0} y={0} z={0}></ObjectDetail>
            </div>
        </div>
    );
}

export default App;
